.sidebar_container {
    display: flex;
    padding: 20px;
    background-color: white;
    width: fit-content;
    border-radius: 0 10px 10px 0;
    box-shadow: 1px 2px 10px 0.2px;
    height: 100vh;
    box-sizing: border-box;
}

.sidebar_content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-width: 230px;
}

.sidebar_inputs_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.logo{
    display: flex;
    gap: 10px;
}

.logo_image {
    display: flex;
    width: 50px;
    height: 50px;

    img {
        object-fit: cover;
    }
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input {
        display: flex;
        width: 90%;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #337ab7;
        
    }
}

.labelInput {
    display: flex;
    align-items: center;
    gap: 5px;
}

.labelImg{
    display: flex;
    width: 20px;
    height: 20px;
    
    img{
        width: 100%;
        height: 100%;
    }
}

.layers {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.layerContainer {
    display: flex;
    gap: 10px;
    
}


.buttonsStyle{
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #337ab7;
    box-shadow: 1px 2px 10px 0.5px;

    button {
        background: transparent;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
        border: none;
        padding: 10px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.disableButtonStyle{
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    box-shadow: 1px 2px 10px 0.5px;

    button {
        background: #337ab7;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        padding: 10px;

        img {
            width: 100%;
            height: 100%;
        }
        
    }
}

.backButton{
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    max-width: 30px;

    img {
        width: 100%;
        height: 100%;
    }
}

.h2Styles{
    font-size: 1rem;
}