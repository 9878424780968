.main_container{
  display: flex;
  height: 100vh;
  gap: 3px;
  
}

#map{
  width: 100%;
}
